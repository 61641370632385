.contact {
  color: white;
  padding-bottom: 5rem;
}
.info {
  padding: 25px;
}

.info {
  display: flex;
  flex-direction: column;
}

.contact .email,
.contact .phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 400px) {
  .contact .email,
  .contact .phone {
    flex-direction: column;
    justify-content: flex-start;

  }
}

.links {
  display: none;
}

.link-card {
  margin-top: 0.75rem;
}

.link-card:hover {
  transform: scale(1.02);
}

.link-card a {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.link-card a p {
  padding: 0 0.25rem 0 1rem;
}

.icon {
  width: 3rem;
  height: 3rem;
}

.contact p,
.contact a,
.contact img {
  user-select: auto;
}

@media screen and (max-width: 1000px) {
  .contact {
    margin: 0 auto;
    width: 90%;
  }
  .links {
    display: block;
  }
}
