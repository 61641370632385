a:hover {
  font-weight: 600;
}

.active {
  color: var(--main-blue) !important;
  font-weight: 600;
}

.number {
  font-size: 0.75rem;
}

.line {
  display: inline-block;
  width: 20px;
  border: 1px solid;
  border-radius: 1px;
  margin-bottom: 4px;
  transition: width 0.15s linear;
  background-color: var(--main-white);
}

.active > .line,
a:hover > .line{
  width: 40px;
}

@media screen and (max-width: 1000px) {
  .line {
    display: none;
  }
}
