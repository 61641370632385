@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --main-gray: #0f1314;
  --main-blue: #5bccf6;
  --main-yellow: #fcde67;
  --background-yellow: #f1d45f6e;
  --main-white: rgb(240, 237, 229);
  font-size: 18px;
}
@media screen and (max-width: 1000px) {
  :root {
    font-size: 16px;
  }
}
@media screen and (max-height: 700px) {
  :root {
    font-size: 16px;
  }
}

html {
  font-family: "Quicksand", sans-serif;
}

body {
  margin: 0;
  background-color: #0f1314;
  background-color: var(--main-gray);
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p,
h1,
h2,
a,
img {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.card {
  background-color: #141a1b;
}

.Contact_contact__2f9gG {
  color: white;
  padding-bottom: 5rem;
}
.Contact_info__2PW1Z {
  padding: 25px;
}

.Contact_info__2PW1Z {
  display: flex;
  flex-direction: column;
}

.Contact_contact__2f9gG .Contact_email__1Psk_,
.Contact_contact__2f9gG .Contact_phone__1x6zg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 400px) {
  .Contact_contact__2f9gG .Contact_email__1Psk_,
  .Contact_contact__2f9gG .Contact_phone__1x6zg {
    flex-direction: column;
    justify-content: flex-start;

  }
}

.Contact_links__1m9Fd {
  display: none;
}

.Contact_link-card__2nY7Z {
  margin-top: 0.75rem;
}

.Contact_link-card__2nY7Z:hover {
  transform: scale(1.02);
}

.Contact_link-card__2nY7Z a {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.Contact_link-card__2nY7Z a p {
  padding: 0 0.25rem 0 1rem;
}

.Contact_icon__2CQcM {
  width: 3rem;
  height: 3rem;
}

.Contact_contact__2f9gG p,
.Contact_contact__2f9gG a,
.Contact_contact__2f9gG img {
  -webkit-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

@media screen and (max-width: 1000px) {
  .Contact_contact__2f9gG {
    margin: 0 auto;
    width: 90%;
  }
  .Contact_links__1m9Fd {
    display: block;
  }
}

.Home_home__PpqoW {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 88px 0 88px 44px;
}



@media screen and (max-width: 1000px) {
  .Home_home__PpqoW {
    flex-direction: row;
    margin: 0 auto;
    position: static;
    width: 85%;
    padding: 88px 0 0 0;
  }
}
@media screen and (max-width: 840px) {
  .Home_home__PpqoW {
    flex-direction: column;
    align-items: center;
  }
}

.Home_text__iX6JN {
  text-align: left;
  color: var(--main-white);
}

.Home_detail__1AxcF {
  margin-top: 3vw;
  color: var(--main-white);
  max-width: 23rem;
}


.Home_home__PpqoW h1 {
  margin: 0;
}

.Home_home__PpqoW p {
  animation: Home_description__ZxlxB 3s ease-out;
}

@keyframes Home_description__ZxlxB {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Home_home__PpqoW h1 {
  font-size: calc(1.5rem + 1vw);
  animation: Home_hi__JPsjI 0.75s ease-out;
}

@keyframes Home_hi__JPsjI {
  0% {
    transform: translateX(-20vw);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.Home_home__PpqoW .Home_blue__1X1el {
  color: var(--main-blue);
}

.Home_down__ECPoZ {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  color: var(--main-yellow);
  animation: Home_jump__1ykR- 0.75s linear;
  animation-iteration-count: 3;
  animation-delay: 1.5s;
  position: absolute;
  bottom: 0;
}

@keyframes Home_jump__1ykR- {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

.MainNavigation_nav__3vcCs {
  display: relative;
  top: 30%;
  left: 2vw;
  z-index: 1;
}

.MainNavigation_nav__3vcCs a {
  text-decoration: none;
  cursor: pointer;
  color: var(--main-white);
  font-size: 1rem;
  position: relative;
  padding: 0.5rem 0;
}

.MainNavigation_nav__3vcCs .MainNavigation_wide-navigation__8R1PC {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  font-weight:100;
}

.MainNavigation_mobile-navigation__2mXYv {
  list-style: none;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  text-align: center;
}

.MainNavigation_nav__3vcCs .MainNavigation_mobile-navigation__2mXYv a {
  width: 100%;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .MainNavigation_nav__3vcCs {
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    min-height: 70px;
    width: 100%;
    border-radius: 3px;
    background-color: var(--main-gray);
    border-bottom: 2px solid #292929;
  }

  .MainNavigation_nav__3vcCs .MainNavigation_wide-navigation__8R1PC {
    display: none;
  }

  .MainNavigation_nav__3vcCs .MainNavigation_mobile-navigation__2mXYv {
    display: flex;
    margin-top: 50px;
    animation: MainNavigation_slide__3iFES 0.3s ease-out;
  }

  @keyframes MainNavigation_slide-down__2SFNj {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

.MainNavigation_hamburger-button__1vASY {
  position: absolute;
  width: 5vw;
  height: 5vw;
  top:10px;
  right: 10px;
  min-width: 50px;
  min-height: 50px;
  border: none;
  color: var(--main-yellow);
  cursor: pointer;
}

@media screen and (min-width: 1001px) {
  .MainNavigation_hamburger-button__1vASY {
    display: none;
  }
}

a:hover {
  font-weight: 600;
}

.NavigationList_active__1Xwjl {
  color: var(--main-blue) !important;
  font-weight: 600;
}

.NavigationList_number__2d23A {
  font-size: 0.75rem;
}

.NavigationList_line__3L2Jx {
  display: inline-block;
  width: 20px;
  border: 1px solid;
  border-radius: 1px;
  margin-bottom: 4px;
  transition: width 0.15s linear;
  background-color: var(--main-white);
}

.NavigationList_active__1Xwjl > .NavigationList_line__3L2Jx,
a:hover > .NavigationList_line__3L2Jx{
  width: 40px;
}

@media screen and (max-width: 1000px) {
  .NavigationList_line__3L2Jx {
    display: none;
  }
}

.ImageAndLinks_image-and-links__10-oV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-white);
}

.ImageAndLinks_image-and-links__10-oV img {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #38d3d31f;
  width: 8rem;
}



.ImageAndLinks_links__3cn0B {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.ImageAndLinks_links__3cn0B a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ImageAndLinks_link-icon__3R3Qp {
  width: 30px;
  height: 30px;
}

.ImageAndLinks_anchor-icon__2P2_H {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 1000px) {
  .ImageAndLinks_image-and-links__10-oV {
    max-width: 390px;
  }
  .ImageAndLinks_image-and-links__10-oV img {
    width: 12rem ;
  }
  .ImageAndLinks_links__3cn0B {
    display: none;
  }
}


.Projects_projects__2jmYv {
  color: var(--main-white);
}

.Projects_container__GLzol {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.Projects_info__1tXoo {
  width: 75%;
}
.Projects_flag__3zPlT {
  margin-bottom: 10px;
  position: relative;
}
.Projects_flag__3zPlT a {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
}

.Projects_flag__3zPlT h3 {
  margin-top: 0;
}
.Projects_description__M3_5A {
  font-size: 0.75rem;
  color: rgb(172, 172, 172);
  margin-bottom: 0;
}
.Projects_flag__3zPlT:hover {
  transform: scale(1.02);
}

.Projects_projects__2jmYv .Projects_img__3lSKH {
  position: relative;
  margin: auto 0;
  width: 20%;
  height: 20%;
  min-width: 50px;
  max-width: 80px;
  border-radius: 5px;
  color: var(--main-white);
}

.Projects_link-icon__3tm9k {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
}

@media screen and (min-width: 1001px) {
  .Projects_link-icon__3tm9k {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .Projects_projects__2jmYv {
    width: 90%;
    margin: 15vw auto;
  }
  .Projects_container__GLzol {
    flex-direction: column;
  }
}

.Footer_footer__2Z31v {
  display: none;
  width: 100%;
  font-size: 0.75rem;
  background-color: #242424;
  color: var(--main-white);
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .Footer_footer__2Z31v {
    display: block;
  }
}




.App_main__1KRQk {
  position: relative;
  width: 45%;
}

.App_details__1qJX0 {
  position: absolute;
  left: 50%;
  padding: 88px 44px 0 0;
  width: 600px;
}

@media screen and (max-width: 1240px) {
  .App_details__1qJX0 {
    width: 50%;
  }
}


@media screen and (max-width: 1000px) {
  .App_main__1KRQk {
    width: 100%;
    position: static;
    height: auto;
  }
  .App_details__1qJX0 {
    width: 100%;
    position: static;
    padding: 0;
  }
}
