@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --main-gray: #0f1314;
  --main-blue: #5bccf6;
  --main-yellow: #fcde67;
  --background-yellow: #f1d45f6e;
  --main-white: rgb(240, 237, 229);
  font-size: 18px;
}
@media screen and (max-width: 1000px) {
  :root {
    font-size: 16px;
  }
}
@media screen and (max-height: 700px) {
  :root {
    font-size: 16px;
  }
}

html {
  font-family: "Quicksand", sans-serif;
}

body {
  margin: 0;
  background-color: var(--main-gray);
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p,
h1,
h2,
a,
img {
  user-select: none;
}

a {
  text-decoration: none;
  color: inherit;
}
